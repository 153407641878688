<template>
  <div>
    <CyDetails
      ref="cy-details"
      :is-read-only="!canUpdateResourcePool"
      :loading="false"
      :can-cancel="canCancel"
      :on-cancel="onCancel"
      :can-save="canSave"
      :on-save="onSave"
      :saving="saving"
      hide-delete>
      <template slot="details_formFullWidth">
        <CyAlert
          class="mx-5 mt-5"
          :content="resourcePoolErrors"
          theme="error"/>

        <v-container
          fluid
          class="form-section px-6 mb-6">
          <v-row align="start">
            <v-col>
              <h3 v-text="$t('generalSettings')"/>
            </v-col>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col/>
            <v-col
              cols="5"
              class="mt-n5 pl-5">
              <v-text-field
                ref="nameField"
                v-model="$v.formContent.name.$model"
                class="required-field"
                :label="$t('forms.fieldName')"
                :hint="$t('formNameHint')"
                :error-messages="nameErrors"
                :loading="!$isCreationRoute && loading"
                :readonly="!canUpdateResourcePool"
                required
                persistent-hint
                tabindex="1"
                @blur="$v.formContent.name.$touch()"/>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>

        <v-divider class="v-divider__forms my-8 mx-6"/>

        <v-container
          fluid
          class="px-6 py-0">
          <v-row
            align="center">
            <v-col>
              <h3 v-text="$t('resources')"/>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters>
            <v-col class="mt-4">
              <i18n
                path="resourcesHint"
                tag="p">
                <template #documentation>
                  <a
                    :href="$docLinks.quotas.resources"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="cy-link"
                    v-text="$t('createResources', { label: _.lowerFirst($t('untranslated.label')) })"/>
                </template>
              </i18n>
            </v-col>
            <v-col
              cols="5"
              class="mt-n6 pl-5"
              align-self="start">
              <v-autocomplete
                ref="labelField"
                v-model="$v.formContent.label.$model"
                :items="availableLabels"
                :label="$t('untranslated.label')"
                :hint="$t('formLabelHint', { label: _.lowerFirst($t('untranslated.label')) })"
                :error-messages="labelErrors"
                :loading="loading"
                required
                persistent-hint
                class="required-field"
                item-text="name"
                item-value="name"
                tabindex="2"
                @blur="$v.formContent.label.$touch()"
                @change="getResourcesByLabel()">
                <template #selection="{ item }">
                  <CyTag variant="default">
                    {{ item.name }}
                  </CyTag>
                </template>
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title class="pb-1">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ formattedResourcesText(item) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template #no-data>
                  <div
                    v-if="_.isEmpty(availableLabels)"
                    class="label-input__no-data px-4 py-1">
                    <div class="label-input__no-data-title">
                      {{ $t('noData.title') }}
                    </div>
                    <span class="label-input__no-data-text">
                      {{ $t('noData.text') }}
                    </span>
                    <CyButton
                      class="create-resource__btn mt-4"
                      icon="add"
                      theme="primary"
                      variant="secondary"
                      :to="{ name: 'newResource' }">
                      {{ $t('inventory.addResource') }}
                    </CyButton>
                  </div>
                  <div
                    v-else
                    class="label-input__no-data px-4 py-1">
                    <div class="label-input__no-data-title">
                      {{ $t('notFound.title') }}
                    </div>
                    <span class="label-input__no-data-text">
                      {{ $t('notFound.text', { label: _.lowerFirst($t('untranslated.label')) }) }}
                    </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col/>
          </v-row>
        </v-container>
      </template>

      <template
        slot="details_secondaryContentFullWidth">
        <div class="resources-selected px-6 mb-8">
          <div
            v-if="loading && !$isCreationRoute"
            class="d-flex justify-center pt-15">
            <v-progress-circular
              indeterminate
              data-cy="loader"
              color="secondary"/>
          </div>
          <div
            v-else-if="!_.isEmpty(formContent.label)"
            class="resources-selected__table">
            <CyResourcePoolLabelTable
              :resources="availableResourcesByLabel"/>
          </div>
          <div
            v-else
            class="resources-selected__no-data">
            <h3
              class="resources-selected__no-data-title"
              v-text="$t('noMatchingResources')"/>
            <p
              class="resources-selected__no-data-text"
              v-text="$t('noMatchingResourcesHint')"/>
          </div>
        </div>

        <v-divider
          v-if="!loading && !$isCreationRoute"
          class="v-divider__forms mx-6"/>

        <CyDangerZone
          v-if="!loading && !$isCreationRoute && canDeleteResourcePool"
          :disabled="saving"
          :delete-text="$t('dangerZone.deleteText')"
          :delete-title="$t('dangerZone.deleteTitle')"
          :entity-type="$t('resourcePool')"
          :entity-name="$data.$originalData.formContent.name"
          class="d-flex justify-center px-6 my-8"
          @delete="onDelete">
          <template #confirmText>
            <p v-html="$sanitizeHtml($t('dangerZone.confirmTextSubtitle', { name: $data.$originalData.formContent.name }))"/>
            <p>{{ $t('dangerZone.confirmTextSentence') }}</p>
          </template>
        </CyDangerZone>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CyDangerZone from '@/components/danger-zone.vue'
import CyDetails from '@/components/details.vue'
import CyResourcePoolLabelTable from '@/components/resource-pool/label-table.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { formatBytes } from '@/utils/helpers/quotas'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageResourcePool',
  components: {
    CyDetails,
    CyResourcePoolLabelTable,
    CyDangerZone,
  },
  breadcrumb () {
    const { $isCreationRoute, resourcePool } = this
    const header = $isCreationRoute
      ? this.$t('quotas.addResourcePool')
      : resourcePool?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.resourcePools'),
        name: 'resourcePools',
      },
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$isCreationRoute ? this.$t('quotas.addResourcePool') : this.$t('editResourcePool'),
      description: {
        text: this.$t('addResourcePoolHint'),
        link: $docLinks.quotas.resourcePools,
      },
    }
  },
  props: {
    resourcePoolCanonical: {
      type: String,
      default: '',
    },
  },
  validations: {
    formContent: {
      name: { required },
      label: { required },
    },
  },
  data: () => ({
    loading: true,
    saving: false,
    formContent: {
      name: '',
      label: '',
    },
  }),
  computed: {
    ...mapState('organization', {
      availableLabels: (state) => state.available.labels,
      availableResourcesByLabel: (state) => state.available.resourcesByLabel,
    }),
    ...mapState('organization/resourcePool', {
      resourcePoolErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/resourcePool', [
      'resourcePool',
    ]),
    canDeleteResourcePool () {
      return this.$cycloid.permissions.canDisplay('DeleteResourcePool')
    },
    canUpdateResourcePool () {
      return this.$cycloid.permissions.canDisplay('UpdateResourcePool')
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    labelErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.label
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canCancel () {
      return _.some([
        this.$hasDataChanged('formContent.name'),
        this.$hasDataChanged('formContent.label'),
      ])
    },
    canSave () {
      const { $v, canCancel } = this
      return canCancel && !$v.$invalid
    },
  },
  async created () {
    this.CLEAR_RESOURCE_POOL_ERRORS()
    await this.fetchResourcePoolAndLabels()
    if (!this.$isCreationRoute) this.getResourcesByLabel()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/resourcePool', [
      'CREATE_RESOURCE_POOL',
      'GET_RESOURCE_POOL',
      'UPDATE_RESOURCE_POOL',
      'DELETE_RESOURCE_POOL',
    ]),
    ...mapMutations('organization/resourcePool', [
      'CLEAR_RESOURCE_POOL_ERRORS',
    ]),
    formatBytes,
    async fetchResourcePoolAndLabels () {
      this.$toggle.loading(true)
      await this.FETCH_AVAILABLE({ keyPath: 'labels', extraParams: ['custom_resources'] })
      if (!this.$isCreationRoute) await this.getResourcePool()
      this.$toggle.loading(false)
    },
    async getResourcePool () {
      const { resourcePoolCanonical } = this
      await this.GET_RESOURCE_POOL({ resourcePoolCanonical })
      if (!_.isEmpty(this.resourcePoolErrors) || _.isEmpty(this.resourcePool)) return
      this.formContent = _.cloneDeep(this.resourcePool)
      this.$setOriginalData()
    },
    async getResourcesByLabel () {
      const { formContent: { label } } = this
      this.$toggle.loading(true)
      await this.FETCH_AVAILABLE({ keyPath: 'resourcesByLabel', extraParams: ['custom_resources', label] })
      this.$toggle.loading(false)
    },
    onCancel () {
      this.$resetData('formContent')
      this.$v.$reset()
    },
    async onSave () {
      this.$toggle.saving(true)

      const { $isCreationRoute, $router, resourcePoolCanonical } = this
      const { name, label } = this.formContent

      const resourcePool = {
        name,
        label,
      }

      $isCreationRoute
        ? await this.CREATE_RESOURCE_POOL({ resourcePool, $router })
        : await this.UPDATE_RESOURCE_POOL({ resourcePool, $router, resourcePoolCanonical })

      if (_.isEmpty(this.resourcePoolErrors)) this.$setOriginalData()
      else this.scrollToTop()

      this.$toggle.saving(false)
    },
    async onDelete () {
      const { formContent: resourcePool, $router } = this
      await this.DELETE_RESOURCE_POOL({ resourcePool, $router })
    },
    formattedResourcesText ({ memory, storage, cpu }) {
      if (!memory || !storage || !cpu) return ''
      const memoryText = `${this.formatBytes(memory)} ${this.$t('quotas.memory').toLowerCase()}`
      const storageText = `${this.formatBytes(storage)} ${this.$t('quotas.storage').toLowerCase()}`
      const cpuText = `${this.$tc('quotas.nCores', cpu)}`
      return `${memoryText} • ${storageText} • ${cpuText}`
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.resourcePool',
        dangerZone: {
          deleteButtonText: 'Delete resource @:resourcePool',
          deleteText: 'Deleting a @:resourcePool is a permanent action that cannot be undone. To delete a @:resourcePool, no quotas must be associated with it.',
          deleteTitle: 'Delete @:resourcePool',
          confirmTextSubtitle: '<b>{name}</b> @:resourcePool is going to be deleted.',
          confirmTextSentence: 'This operation might fail if some quotas are in use. You will have to delete them and try again.',
        },
        editResourcePool: 'Edit @:resourcePool',
        addResourcePoolHint: 'Resource pools are groups of resources to assign quotas to.',
        createResources: 'create and {label} your resources',
        formLabelHint: 'Select the {label} to identify resources that will form this @:resourcePool',
        formNameHint: 'A descriptive name to identify this @:resourcePool',
        generalSettings: 'General settings',
        resources: '@:Resources',
        resourcesHint: 'Target inventory resources of the “Custom resources” provider that match the selected label . Learn how to {documentation}.',
        noData: {
          title: 'No labels found.',
          text: 'Add resources with label before you can select one here.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: 'There is no {label} matching this search on the servers listed in your inventory.',
        },
        noMatchingResources: 'No matching resources',
        noMatchingResourcesHint: 'Select a label that matches some existing resources',
      },
      es: {
        title: '@:routes.resourcePool',
        dangerZone: {
          deleteButtonText: 'Borrar @:resourcePool',
          deleteText: 'La eliminación de un @:resourcePool es una acción permanente que no se puede deshacer. Para eliminar un @:resourcePool, no debe tener quotas asociadas.',
          deleteTitle: 'Eliminar @:resourcePool',
          confirmTextSubtitle: 'El @:resourcePool <b>{name}</b> se eliminará.',
          confirmTextSentence: 'Esta operación puede fallar si algunas quotas están en uso. Tendrás que eliminarlas e intentarlo de nuevo.',
        },
        editResourcePool: 'Editar el @:resourcePool',
        addResourcePoolHint: 'Los grupos de recursos son recursos a los que asignar quotas.',
        createResources: 'crear y asignar una {label} a sus recursos',
        formLabelHint: 'Seleccione la {label} para identificar los recursos que formarán este @:resourcePool',
        formNameHint: 'Un nombre descriptivo para identificar este @:resourcePool',
        generalSettings: 'Ajustes generales',
        resources: '@:Resources',
        resourcesHint: 'Apunta a los recursos de inventario del proveedor de "Recursos personalizados" que coinciden con la label seleccionada. Aprenda a {documentation}.',
        noData: {
          title: 'No se encontraron labels.',
          text: 'Agregue recursos con una label antes de poder seleccionar uno aquí.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: 'No hay ninguna {label} que coincida con esta búsqueda en los servidores que figuran en su inventario.',
        },
        noMatchingResources: 'No hay recursos que coincidan',
        noMatchingResourcesHint: 'Seleccione una label que coincida con algunos recursos existentes',
      },
      fr: {
        title: '@:routes.resourcePool',
        dangerZone: {
          deleteButtonText: 'Supprimer le @:resourcePool',
          deleteText: `La suppression d'un @:resourcePool est une action permanente qui ne peut pas être annulée. Pour supprimer un @:resourcePool, aucun quota ne doit lui être associé.`,
          deleteTitle: 'Supprimer le @:resourcePool',
          confirmTextSubtitle: 'Le @:resourcePool <b>{name}</b> va être supprimé.',
          confirmTextSentence: 'Cette opération peut échouer si certains quotas sont utilisés. Vous devrez les supprimer et réessayer.',
        },
        editResourcePool: 'Modifier le @:resourcePool',
        addResourcePoolHint: 'Les pools de ressources sont des groupes de ressources auxquels attribuer des quotas.',
        createResources: 'créer et {label} vos ressources',
        formLabelHint: 'Sélectionnez le {label} pour identifier les ressources qui formeront ce @:resourcePool',
        formNameHint: 'Un nom descriptif pour identifier ce @:resourcePool',
        generalSettings: 'Réglages généraux',
        resources: '@:Resources',
        resourcesHint: `Ciblez les ressources d'inventaire du fournisseur de "Ressources personnalisées" qui correspondent au label sélectionné. Découvrez comment  {documentation}.`,
        noData: {
          title: 'Aucun label trouvé',
          text: 'Ajouter des ressources avec une label avant de pouvoir en sélectionner un ici.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: `Il n'y a pas de {label} correspondant à cette recherche sur les serveurs répertoriés dans votre inventaire.`,
        },
        noMatchingResources: 'Aucune ressource correspondante',
        noMatchingResourcesHint: 'Sélectionnez un label correspondant à certaines ressources existantes',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

::v-deep .cy-details {
  &__content {
    padding: 0;
    overflow-y: visible;
  }

  &__section {
    height: auto;

    & + & {
      margin-top: 32px;
    }
  }
}

::v-deep .danger-zone {
  &__content {
    width: 715px;
  }

  &__title {
    position: absolute;
    left: 25px;
  }
}

::v-deep .v-divider__forms {
  max-width: 69%;
}

::v-deep .v-list-item__subtitle {
  color: map.get($slate-grey, "light-2") !important;
  font-size: $font-size-default;
  line-height: 21px;
}

.label-input {
  &__no-data {
    &-title {
      font-weight: $font-weight-bolder;
    }

    &-title,
    &-text {
      display: block;
      color: cy-get-color("primary");
    }
  }
}

.resources-selected {
  &__no-data {
    @extend %dashed-border;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 256px;

    &-title {
      color: cy-get-color("primary", "light-2");
    }

    &-text {
      color: cy-get-color("primary", "light-3");
    }
  }
}
</style>
