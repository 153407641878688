<template>
  <v-data-table
    class="elevation-1"
    v-bind="$attrs"
    :headers="$static.headers"
    :items="formattedResources()"
    disable-pagination
    hide-default-footer>
    <template #foot>
      <tfoot>
        <tr>
          <td v-text="$t('total')"/>
          <td/>
          <td v-text="formatBytes(_.sumBy(resources, 'memory'))"/>
          <td v-text="formatBytes(_.sumBy(resources, 'storage'))"/>
          <td v-text="`${$tc('quotas.nCores', _.sumBy(resources, 'cpu'))}`"/>
        </tr>
      </tfoot>
    </template>
  </v-data-table>
</template>

<script>
import { formatBytes } from '@/utils/helpers/quotas'

export default {
  name: 'CyResourcePoolLabelTable',
  props: {
    resources: {
      type: Array,
      required: true,
    },
  },
  computed: {
    $static () {
      return {
        headers: [
          {
            text: this.$t('name'),
            value: 'name',
          },
          {
            text: this.$t('untranslated.id'),
            value: 'id',
            sortable: false,
          },
          {
            text: this.$t('quotas.memory'),
            value: 'memory',
            sortable: false,
          },
          {
            text: this.$t('quotas.storage'),
            value: 'storage',
            sortable: false,
          },
          {
            text: this.$t('quotas.cpu'),
            value: 'cpu',
            sortable: false,
          },
        ],
      }
    },
  },
  methods: {
    formatBytes,
    formattedResources () {
      return this.resources.map((resource) => {
        const formattedMemory = formatBytes(resource.memory)
        const formattedStorage = formatBytes(resource.storage)
        const formattedCpu = this.$tc('quotas.nCores', resource.cpu)

        return {
          ...resource,
          memory: formattedMemory,
          storage: formattedStorage,
          cpu: formattedCpu,
        }
      })
    },
  },
  i18n: {
    messages: {
      en: {
        total: 'Total capacity',
      },
      es: {
        total: 'Capacidad total',
      },
      fr: {
        total: 'Capacité totale',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table__wrapper {
  tfoot tr {
    background-color: map.get($grey, "light-4");
    font-weight: $font-weight-bolder;
  }
}
</style>
